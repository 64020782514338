<template>
  <div class="notifications">
    <div class="notifications-loader" v-if="loading">
      <div class="notifications-loader-container">
        <span class="loader">
          <svg
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="381.496px"
            height="381.496px"
            viewBox="0 0 381.496 381.496"
            style="enable-background: new 0 0 381.496 381.496"
            xml:space="preserve"
          >
            <g>
              <g>
                <g id="Layer_5_13_">
                  <g>
                    <path
                      d="M353.165,182.381c1.217-2.613,9.533-13.636,17.062-25.062c0.007-0.008,0.013-0.016,0.017-0.023
                          c1.699-2.578,3.355-5.175,4.885-7.702c0.043-0.071,0.086-0.143,0.129-0.214c0.248-0.412,17.859-28.646-7.225-17.212
                          c0,0.002-0.002,0.005-0.004,0.007c-4.713,2.417-10.707,6.021-18.244,11.072c-16.441,11.021-49.885,27.154-49.885,27.154
                          s-5.82,3.144-9.658,0.749c-19.396-12.1-47.656-33.594-84.912-45.562c-0.621-0.2-1.408-0.673-0.338-1.664l15.955-11.158
                          c0,0,1.25-1.08-0.355-1.602c-7.896-2.573-40.783-13.601-69.24-3.462c-5.797,2.065-10.555,3.761-14.467,5.155
                          c-1.682,0.6-3.391,1.305-6.799,1.726C52.482,117.237,0,174.203,0,196.737c0,15.123,8.154,25.271,37.947,39.378
                          c0.598-0.095,5.146,3.17,15.137,0.168c2.678-0.805,21.697-7.968,22.453-8.291c0.758-0.346,1.25-0.517,1.564-0.466
                          c0.404,0.064,0.701,0.962,0.699,1.144c-0.063,5.387-10.16,9.75-15.893,14.537c-0.984,0.459-1.248,2.744,0.475,3.484
                          c0.002,0,20.246,10.854,52.307,14.229c2.592,0.273,36.34,21.897,70.371,16.096c17.999-3.069,26.564-4.119,30.473-5.197
                          c3.412-0.94,1.783-2.022,1.783-2.022l-17.059-13.592c-1.155-1.281-0.221-2.265,0.746-2.539
                          c37.882-10.779,67.834-27.771,85.672-42.328c2.402-1.961,8.645,2.701,13.102,4.953c14.801,7.477,76.238,32.803,81.301,27.442
                          c0.436-0.452,0.467-1.125,0.023-2.05C372.456,223.524,341.21,208.035,353.165,182.381z M62.835,180.632
                          c-5.465,0-9.895-4.512-9.895-10.077s4.43-10.076,9.895-10.076s9.896,4.511,9.896,10.076S68.3,180.632,62.835,180.632z
                          M107.118,237.965c-0.609,0.547-1.164,1.373-0.842,0.185c0,0,15.426-23.21,17.426-53.211
                          c1.498-22.484-13.482-50.02-13.482-50.02s0.029-0.804,0.555-0.169C116.108,141.2,168.618,182.688,107.118,237.965z"
                    />
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </span>
        <h4>Saving post...</h4>
      </div>
    </div>

    <div class="view-nav notifications-nav">
      <span
        :class="{ active: currentNav === 'list' }"
        @click="internalNavigation('list')"
        >All posts</span
      >
      <span
        :class="{ active: currentNav === 'create-edit' }"
        @click="internalNavigation('create-edit')"
        >{{ editing !== null ? "Edit post" : "Create new" }}</span
      >
    </div>

    <!-- Filtration screen -->
    <div
      v-if="currentNav === 'create-edit' && !builder"
      class="notifications-create"
    >
      <!-- Filter selection boxes -->
      <h2>Select categories, companies and users</h2>

      <div class="notifications-filter-buttons">
        <button @click="clearSelections">Clear all</button>
        <button @click="selectAll">Select all</button>
      </div>

      <PostsSelectionBoxes />

      <h2>Confirm selection</h2>

      <div class="notifications-selected">
        <!-- Selection list -->
        <PostsFilterSelections />
        <PostsSelectionFinalCompanies />
      </div>

      <button @click="$store.commit('notifications/setBuilder', true)">
        Continue
      </button>
    </div>

    <!-- Builder -->
    <div
      v-if="currentNav === 'create-edit' && builder"
      class="notifications-builder"
    >
      <div class="notifications-info-fields">
        <span class="released-info" v-if="released"
          >Post has been released, meta fields can no longer be changed.</span
        >
        <div class="notifications-sub-section" :class="{ active: released }">
          <h5>Company selection</h5>
          <PostsFilterSelections
            class="builder-selections"
            ref="builderFilterSelection"
          />
          <span
            class="builder-back"
            @click="$store.commit('notifications/setBuilder', false)"
            >&larr; Change selection</span
          >

          <h5>Post meta</h5>
          <input
            ref="mainTitle"
            v-model="mainTitle"
            type="text"
            placeholder="Title"
          />
          <textarea
            ref="mainDescription"
            v-model="mainDescription"
            type="text"
            placeholder="Beskrivning"
          ></textarea>
          <date-picker
            ref="startTime"
            v-model="startTime"
            type="datetime"
            valueType="format"
            :showSecond="false"
            placeholder="Select start time"
            format="YYYY-MM-DD — HH:mm"
          ></date-picker>
          <date-picker
            ref="endTime"
            v-model="endTime"
            type="datetime"
            valueType="format"
            :showSecond="false"
            placeholder="Select end time"
            format="YYYY-MM-DD — HH:mm"
          ></date-picker>

          <div class="featured-image-preview" v-if="featuredImage.length > 0">
            <img :src="featuredImage[0].blob" />
          </div>
          <div
            class="featured-image-preview"
            v-else-if="
              savedFeaturedImage && imageUrls[savedFeaturedImage] !== undefined
            "
          >
            <img :src="imageUrls[savedFeaturedImage]" />
          </div>
          <file-upload
            input-id="file2"
            ref="featuredUpload"
            extensions="png,jpg,jpeg"
            accept="image/png,image/jpg,image/jpeg"
            v-model="featuredImage"
            @input="uploadFeaturedImage"
            @input-filter="inputFilter"
            :multiple="false"
            :drop="false"
            class="featured-file-upload"
          >
            <span class="material-icons"> image </span>
            <span v-if="featuredImage.length > 0 || savedFeaturedImage"
              >Change featured image</span
            >
            <span v-else>Upload featured image</span>
          </file-upload>
          <div class="notifications-post-type" ref="postType">
            <input type="radio" id="news" value="news" v-model="postType" />
            <label for="news">News</label>

            <input type="radio" id="offer" value="offer" v-model="postType" />
            <label for="offer">Offer</label>
          </div>
          <div class="send-notification">
            <input type="checkbox" id="checkbox-chat" v-model="sendChat" />
            <label for="checkbox-chat">Send chat message?</label>
          </div>
          <div class="send-notification" v-if="sendChat">
            <input
              type="checkbox"
              id="checkbox-notification"
              v-model="sendNotification"
            />
            <label for="checkbox-notification">Send push notification?</label>
          </div>
          <input
            type="text"
            v-if="sendNotification && sendChat"
            placeholder="Notification content.."
            ref="notificationText"
            v-model="notificationText"
            @focus="showHomeScreen = true"
            @blur="showHomeScreen = false"
          />

          <h5>Finish up</h5>
          <div class="notifications-post-type" ref="postStatus">
            <input type="radio" id="draft" value="draft" v-model="postStatus" />
            <label for="draft">Draft</label>

            <input
              type="radio"
              id="publish"
              value="publish"
              v-model="postStatus"
            />
            <label for="publish">Publish</label>
          </div>
        </div>

        <div class="builder-buttons">
          <button class="leave-button" @click="exitEditing">Exit</button>
          <button @click="savePost">
            {{ editing !== null ? "Save" : "Create" }}
          </button>
        </div>

        <div v-if="imagesFailed" class="builder-error">
          <p>Images failed to upload, please re-add them and try again.</p>
        </div>

        <div class="builder-leave" v-if="editing !== null">
          <button class="delete-button" @click="deletePost">Delete post</button>
        </div>
      </div>

      <div class="notifications-previewer">
        <div class="phone-wrapper">
          <div class="phone-container">
            <div class="app-home-screen" :class="{ active: showHomeScreen }">
              <div class="app-home-screen-static">
                <span class="material-icons">lock</span>
                <span class="time">17:00</span>
                <span class="date">friday 13 august</span>
              </div>

              <div class="app-home-screen-notification">
                <div class="notification-container">
                  <div class="logo">
                    <img
                      :src="require('../assets/' + appBrand + '/app-icon.png')"
                    />
                  </div>
                  <div class="notification-content">
                    <div class="notification-header">
                      <span class="title">{{ postType }}</span>

                      <span class="app-timestamp"> now </span>
                    </div>
                    <span class="content">{{ notificationText }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="app-header"></div>

            <div class="phone-top-bar">
              <span>17:00</span>
              <div class="notch"></div>
              <span>. . . .</span>
            </div>

            <div class="app-menu">
              <span class="active">All</span>
              <span>Offers</span>
              <span>News</span>
            </div>

            <div class="app-content">
              <div class="app-content-header">
                <h3>{{ mainTitle === "" ? "Title" : mainTitle }}</h3>

                <draggable
                  v-model="blocks"
                  @start="onDragStart"
                  @end="onDragEnd"
                >
                  <div
                    v-for="(block, index) in blocks"
                    :key="'block-' + index"
                    class="app-block"
                    :class="{ active: index === selectedBlockIndex }"
                    @click="selectBlock(index)"
                  >
                    <div v-if="block.type === 'title'" class="title-block">
                      <h2>{{ block.title }}</h2>
                    </div>

                    <div
                      v-else-if="block.type === 'content'"
                      class="content-block"
                    >
                      <p>{{ block.content }}</p>
                    </div>

                    <div
                      v-else-if="block.type === 'product'"
                      class="product-block"
                    >
                      <div class="product-block-container">
                        <img
                          :src="require('../assets/' + appBrand + '/fish.png')"
                        />

                        <div class="product-block-title-description">
                          <h6>
                            {{
                              block.productId !== null
                                ? getProductByFirebaseId(block.productId).name
                                : "Select a product"
                            }}
                          </h6>
                          <p
                            v-if="
                              block.productId !== null &&
                              getProductByFirebaseId(
                                block.productId
                              ).description.trim()
                            "
                          >
                            {{
                              getProductByFirebaseId(block.productId)
                                .description
                            }}
                          </p>
                        </div>

                        <div class="product-block-add-to-cart">
                          <span class="material-icons decrease"
                            >remove_circle</span
                          >
                          <span class="quantity">0</span>
                          <span v-if="block.productId" class="unit">{{
                            getProductByFirebaseId(block.productId).unit
                          }}</span>
                          <span class="material-icons increase"
                            >add_circle</span
                          >
                        </div>
                      </div>
                    </div>

                    <div v-else-if="block.type === 'image'" class="image-block">
                      <img
                        v-if="block.doUpload && block.imageBlob !== null"
                        :src="block.imageBlob"
                      />
                      <img
                        v-else-if="
                          imageUrls[block.imageUniqueName] !== undefined
                        "
                        :src="imageUrls[block.imageUniqueName]"
                      />
                      <img
                        v-else-if="block.imageBlob !== null"
                        :src="block.imageBlob"
                      />
                      <div v-else class="image-placeholder">
                        Upload an image
                      </div>
                    </div>

                    <div
                      v-else-if="block.type === 'button'"
                      class="button-block"
                    >
                      <a target="_blank">{{ block.text }}</a>
                    </div>
                  </div>
                </draggable>
              </div>
            </div>

            <div class="app-footer">
              <div class="block-selector">
                <div
                  class="block-select-dropdown"
                  @click="newBlockOpen = !newBlockOpen"
                >
                  <span class="selected">
                    Add new block
                    <span
                      class="material-icons"
                      :class="{ active: newBlockOpen }"
                      >expand_more</span
                    >
                  </span>
                  <div class="options" v-if="newBlockOpen">
                    <span
                      v-for="(type, index) in blockTypes"
                      :key="'block-select-' + index"
                      @click.stop="addBlock(type)"
                      >{{ type }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <PostsTemplateHandler />
      </div>

      <div
        v-if="selectedBlockIndex !== null"
        class="notifications-builder-fields"
      >
        <div class="notification-builder-controls">
          <span
            :class="{ inactive: selectedBlockIndex === 0 }"
            @click="blockMoveUp"
            class="material-icons"
            >arrow_upward</span
          >
          <span
            :class="{ inactive: selectedBlockIndex === blocks.length - 1 }"
            @click="blockMoveDown"
            class="material-icons"
            >arrow_downward</span
          >
          <span @click="duplicateBlock" class="material-icons"
            >content_copy</span
          >
          <span @click="deleteBlock" class="material-icons">delete</span>
        </div>

        <div v-if="selectedBlock.type === 'title'" class="title-builder">
          <h3>Title block</h3>
          <input
            type="text"
            :value="selectedBlock.title"
            @input="updateTitleField"
          />
        </div>

        <div v-if="selectedBlock.type === 'content'" class="content-builder">
          <h3>Content block</h3>
          <textarea
            style="height: 175px"
            :value="selectedBlock.content"
            @input="updateContentField"
          />
        </div>

        <div v-if="selectedBlock.type === 'image'" class="image-builder">
          <h3>Image block</h3>
          <file-upload
            input-id="file1"
            ref="upload"
            extensions="png,jpg,jpeg"
            accept="image/png,image/jpg,image/jpeg"
            v-model="files"
            @input="uploadImage"
            @input-filter="inputFilter"
            :multiple="false"
            :drop="true"
            class="file-upload"
          >
            <span class="material-icons"> image </span>
          </file-upload>
        </div>

        <div v-if="selectedBlock.type === 'product'" class="product-builder">
          <h3>Product block</h3>
          <div
            class="selected-product-dropdown"
            @click="selectProductOpen = !selectProductOpen"
          >
            <span class="selected">
              {{
                selectedBlock.productId === null
                  ? "Select a product"
                  : getProductByFirebaseId(selectedBlock.productId).name
              }}
              <span
                class="material-icons"
                :class="{ active: selectProductOpen }"
                >expand_more</span
              >
            </span>

            <div class="search" v-if="selectProductOpen" @click.stop="">
              <input
                ref="productSearchField"
                type="text"
                placeholder="Search product by name or ID"
                v-model="selectProductSearch"
              />
            </div>

            <div class="options" v-if="selectProductOpen">
              <span
                v-for="product in getProductsBySearch(selectProductSearch)"
                :key="'product-select-' + product.firebaseId"
                @click.stop="selectProduct(product.firebaseId)"
                >{{ product.vismaId }} — {{ product.name }}</span
              >
            </div>
          </div>
        </div>

        <div v-if="selectedBlock.type === 'button'" class="button-builder">
          <h3>Button block</h3>
          <input
            type="text"
            :value="selectedBlock.text"
            @input="updateButtonTextField"
          />

          <input
            type="text"
            :value="selectedBlock.url"
            @input="updateButtonUrlField"
            placeholder="Klistra in URL"
          />

          <div class="button-link-type">
            <div class="option" @click="updateButtonLinkType('internal')">
              <div
                class="check"
                :class="{ active: selectedBlock.linkType == 'internal' }"
              ></div>
              <span>Internal</span>
            </div>
            <div class="option" @click="updateButtonLinkType('external')">
              <div
                class="check"
                :class="{ active: selectedBlock.linkType == 'external' }"
              ></div>
              <span>External</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="currentNav === 'list'" class="notifications-post-list">
      <PostsListing />
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapState, mapGetters } from "vuex";
import DatePicker from "vue2-datepicker";

import "vue2-datepicker/index.css";
import draggable from "vuedraggable";

export default {
  name: "Notifications",
  data() {
    return {
      newBlockOpen: false,
      selectProductOpen: false,
      selectProductSearch: "",
      files: [],
      showHomeScreen: false,
      imagesFailed: false,
      loading: false,
      featuredImage: [],
    };
  },
  components: { DatePicker, draggable },
  computed: {
    ...mapState({
      companyCategories: (state) => state.settings.taxonomies.categories,
      currentNav: (state) => state.notifications.currentNav,
      excludedCompanies: (state) => state.notifications.excludedCompanies,
      builder: (state) => state.notifications.builder,
      appBrand: (state) => state.settings.appSettings.app,
      blockTypes: (state) => state.notifications.blockTypes,
      selectedBlockIndex: (state) => state.notifications.selectedBlockIndex,
      userName: (state) => state.settings.user.name,
      userId: (state) => state.settings.user.uid,
      editing: (state) => state.notifications.editing,
      released: (state) => state.notifications.released,
      imageUrls: (state) => state.notifications.imageUrls,
      init: (state) => state.notifications.init,
      savedFeaturedImage: (state) => state.notifications.featuredImage,
    }),
    ...mapGetters("companies", ["getCompanyByVismaId"]),
    ...mapGetters("notifications", ["selectedBlock", "finalCompanies"]),
    ...mapGetters("products", [
      "getProductByFirebaseId",
      "getProductsBySearch",
    ]),
    mainTitle: {
      get() {
        return this.$store.state.notifications.mainTitle;
      },
      set(value) {
        this.$store.commit("notifications/setMainTitle", value);
      },
    },
    mainDescription: {
      get() {
        return this.$store.state.notifications.mainDescription;
      },
      set(value) {
        this.$store.commit("notifications/setMainDescription", value);
      },
    },
    startTime: {
      get() {
        return this.$store.state.notifications.startTime;
      },
      set(value) {
        this.$store.commit("notifications/setStartTime", value);
      },
    },
    endTime: {
      get() {
        return this.$store.state.notifications.endTime;
      },
      set(value) {
        this.$store.commit("notifications/setEndTime", value);
      },
    },
    postType: {
      get() {
        return this.$store.state.notifications.postType;
      },
      set(value) {
        this.$store.commit("notifications/setPostType", value);
      },
    },
    postStatus: {
      get() {
        return this.$store.state.notifications.postStatus;
      },
      set(value) {
        this.$store.commit("notifications/setPostStatus", value);
      },
    },
    sendNotification: {
      get() {
        return this.$store.state.notifications.sendNotification;
      },
      set(value) {
        this.$store.commit("notifications/setSendNotification", value);
      },
    },
    sendChat: {
      get() {
        return this.$store.state.notifications.sendChat;
      },
      set(value) {
        this.$store.commit("notifications/setSendChat", value);
      },
    },
    blocks: {
      get() {
        return this.$store.state.notifications.blocks;
      },
      set(value) {
        this.$store.commit("notifications/updateBlocks", value);
      },
    },
    notificationText: {
      get() {
        return this.$store.state.notifications.notificationText;
      },
      set(value) {
        this.$store.commit("notifications/setNotificationText", value);
      },
    },
  },
  async created() {
    if (!this.init) {
      await this.$store.dispatch("notifications/bindPosts");
      await this.$store.dispatch("notifications/bindTemplates");
      this.$store.commit("notifications/setInit", true);
    }
  },
  beforeDestroy() {
    this.$store.dispatch("notifications/unbindPosts");
    this.$store.dispatch("notifications/unbindTemplates");
    this.$store.commit("notifications/setInit", false);
  },
  methods: {
    savePost: function () {
      if (this.loading) {
        return;
      }

      let valid = true;

      this.$refs.mainTitle.classList.remove("error");
      this.$refs.startTime.$el.classList.remove("error");
      this.$refs.endTime.$el.classList.remove("error");
      this.$refs.postType.classList.remove("error");
      this.$refs.postStatus.classList.remove("error");
      this.$refs.builderFilterSelection.$el.classList.remove("error");
      this.$refs.featuredUpload.$el.classList.remove("error");

      if (this.postStatus === "publish") {
        if (this.finalCompanies.length === 0) {
          this.$refs.builderFilterSelection.$el.classList.add("error");
        }

        if (this.mainTitle === "") {
          this.$refs.mainTitle.classList.add("error");
          valid = false;
        }

        if (this.startTime === null) {
          this.$refs.startTime.$el.classList.add("error");
          valid = false;
        }

        if (
          this.featuredImage.length === 0 &&
          (this.featuredImage === undefined || this.featuredImage === null)
        ) {
          this.$refs.featuredUpload.$el.classList.add("error");
          valid = false;
        }

        if (this.postType === "offer" && this.endTime === null) {
          this.$refs.endTime.$el.classList.add("error");
          valid = false;
        }

        if (this.postType === null) {
          this.$refs.postType.classList.add("error");
          valid = false;
        }

        if (this.postStatus === null) {
          this.$refs.postStatus.classList.add("error");
          valid = false;
        }

        if (this.sendNotification === true && this.notificationText === "") {
          this.$refs.notificationText.classList.add("error");
          valid = false;
        }
      }

      if (valid) {
        this.loading = true;
        this.imagesFailed = false;
        console.log("send it");
        this.$store
          .dispatch("notifications/savePost", {
            status: status,
            authorId: this.userId,
            authorName: this.userName,
          })
          .then((res) => {
            console.log(res);
            this.loading = false;

            this.$toast.center("Post saved");

            this.featuredImage = [];

            if (res === "failed") {
              this.imagesFailed = true;
            } else if (res === "success") {
              this.$store.commit("notifications/setNavigation", "list");
            }
          });
      }
    },
    uploadImage: function (value) {
      console.log(value);

      if (value.length > 0) {
        console.log("Uploaded file size: " + value[0].size);

        // If this fails, no preview will be available.
        if (
          value[0].size > 0 &&
          this.selectedBlockIndex !== null &&
          this.selectedBlock.type === "image"
        ) {
          this.selectedBlock.imageBlob = value[0].blob;
          this.selectedBlock.imageFile = value[0].file;
          this.selectedBlock.imageFileType = value[0].type;
          this.selectedBlock.doUpload = true;
          this.selectedBlock.imageUrl = null;
        }
      }
    },
    uploadFeaturedImage: function (value) {
      console.log(value);
      console.log("featured");

      if (value.length > 0) {
        console.log("Uploaded file size: " + value[0].size);

        this.$store.commit("notifications/setFeaturedImage", value[0]);
        this.$store.commit("notifications/setDoFeaturedUpload", true);
      }
    },
    blockMoveUp: function () {
      if (this.selectedBlockIndex !== null && this.selectedBlockIndex !== 0) {
        // let blocks = JSON.parse(JSON.stringify(this.blocks));
        let newBlocks = [];

        // Json parse/stringify removes files..
        this.blocks.forEach((block) => {
          newBlocks.push(block);
        });

        newBlocks.splice(
          this.selectedBlockIndex - 1,
          0,
          newBlocks.splice(this.selectedBlockIndex, 1)[0]
        );

        this.$store.commit("notifications/updateBlocks", newBlocks);
        this.$store.commit(
          "notifications/setSelectedBlockIndex",
          this.selectedBlockIndex - 1
        );
      }
    },
    blockMoveDown: function () {
      if (
        this.selectedBlockIndex !== null &&
        this.selectedBlockIndex !== this.blocks.length - 1
      ) {
        // let blocks = JSON.parse(JSON.stringify(this.blocks));
        let newBlocks = [];

        // Json parse/stringify removes files..
        this.blocks.forEach((block) => {
          newBlocks.push(block);
        });

        newBlocks.splice(
          this.selectedBlockIndex + 1,
          0,
          newBlocks.splice(this.selectedBlockIndex, 1)[0]
        );

        this.$store.commit("notifications/updateBlocks", newBlocks);
        this.$store.commit(
          "notifications/setSelectedBlockIndex",
          this.selectedBlockIndex + 1
        );
      }
    },
    duplicateBlock: function () {
      if (this.selectedBlockIndex !== null) {
        console.log(this.selectedBlock);
        let duplication = Object.assign({}, this.selectedBlock);
        // let blocks = JSON.parse(JSON.stringify(this.blocks));
        let newBlocks = [];

        // Json parse/stringify removes files..
        this.blocks.forEach((block) => {
          newBlocks.push(block);
        });

        newBlocks.splice(this.selectedBlockIndex + 1, 0, duplication);

        this.$store.commit("notifications/updateBlocks", newBlocks);
        this.$store.commit(
          "notifications/setSelectedBlockIndex",
          this.selectedBlockIndex + 1
        );

        this.selectProductOpen = false;
        this.selectProductSearch = "";
        this.newBlockOpen = false;
      }
    },
    deleteBlock: function () {
      let confirm = window.confirm(
        "About to delete block, do you wish to continue?"
      );

      if (confirm) {
        // let blocks = JSON.parse(JSON.stringify(this.blocks));
        let newBlocks = [];

        // Json parse/stringify removes files..
        this.blocks.forEach((block) => {
          newBlocks.push(block);
        });

        newBlocks.splice(this.selectedBlockIndex, 1);
        this.$store.commit("notifications/updateBlocks", newBlocks);
        this.$store.commit("notifications/setSelectedBlockIndex", null);
      }
    },
    updateTitleField: function (event) {
      this.selectedBlock.title = event.srcElement.value;
    },
    updateContentField: function (event) {
      this.selectedBlock.content = event.srcElement.value;
    },
    updateButtonTextField: function (event) {
      this.selectedBlock.text = event.srcElement.value;
    },
    updateButtonUrlField: function (event) {
      this.selectedBlock.url = event.srcElement.value;
    },
    updateButtonLinkType: function (value) {
      this.selectedBlock.linkType = value;
    },
    internalNavigation: function (to) {
      console.log(to);
      if (this.currentNav === to) {
        return false;
      }

      this.$store.commit("notifications/setNavigation", to);
    },
    clearSelections: function () {
      this.$store.commit("notifications/setSelectedCompanyCategories", []);
      this.$store.commit("notifications/setSelectedPriceGroups1", []);
      this.$store.commit("notifications/setSelectedPriceGroups2", []);
      this.$store.commit("notifications/setSelectedPriceGroups3", []);
      this.$store.commit("notifications/setExcludedPriceGroups1", []);
      this.$store.commit("notifications/setExcludedPriceGroups2", []);
      this.$store.commit("notifications/setExcludedPriceGroups3", []);
      this.$store.commit("notifications/setSelectedCompanies", []);
      this.$store.commit("notifications/setSelectedSalesAdmins", []);
      this.$store.commit("notifications/setExcludedCompanies", []);
    },
    selectAll: function () {
      this.clearSelections();

      this.$store.dispatch("notifications/selectAll", this.companyCategories);
      console.log("presss");
    },
    selectBlock: function (index) {
      this.selectProductOpen = false;
      this.selectProductSearch = "";

      if (this.selectedBlockIndex === index) {
        index = null;
      }

      this.$store.commit("notifications/setSelectedBlockIndex", index);
    },
    onDragStart: function (evt) {
      this.$store.commit("notifications/setSelectedBlockIndex", evt.oldIndex);
    },
    onDragEnd: function (evt) {
      // console.log({
      //   'item': evt.item,
      //   'from': evt.from,
      //   'to': evt.to,
      //   'oldIndex': evt.oldIndex,
      //   'newIndex': evt.newIndex
      // });

      this.$store.commit("notifications/setSelectedBlockIndex", evt.newIndex);
    },
    selectProduct: function (id) {
      if (
        this.selectedBlockIndex === null &&
        this.selectedBlock.type !== "product"
      ) {
        return false;
      }

      this.selectedBlock.productId = id;
      this.selectProductOpen = false;
      this.selectProductSearch = "";
    },
    /**
     * Pretreatment
     * @param  Object|undefined   newFile   Read and write
     * @param  Object|undefined   oldFile   Read only
     * @param  Function           prevent   Prevent changing
     * @return undefined
     */
    inputFilter: function (newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Filter non-image file
        if (!/\.(jpeg|jpe|jpg|png)$/i.test(newFile.name)) {
          return prevent();
        }
      }

      // Create a blob field
      newFile.blob = "";
      let URL = window.URL || window.webkitURL;
      if (URL && URL.createObjectURL) {
        newFile.blob = URL.createObjectURL(newFile.file);
      }
    },
    addBlock: function (blockType) {
      this.newBlockOpen = false;
      this.selectProductOpen = false;
      this.selectProductSearch = "";

      let blockData;

      switch (blockType) {
        case "title":
          blockData = {
            type: "title",
            title: "Enter title",
          };
          break;
        case "content":
          blockData = {
            type: "content",
            content: "Enter text",
          };
          break;
        case "image":
          blockData = {
            type: "image",
            imageBlob: null,
            imageFile: null,
            imageFileType: null,
            imageUniqueName: null,
            imageUrl: null,
            doUpload: false,
          };
          break;
        case "product":
          blockData = {
            type: "product",
            productId: null,
          };
          break;
        case "button":
          blockData = {
            type: "button",
            text: "Enter button text",
            url: "",
            linkType: "internal",
          };
          break;
      }

      this.$store.commit("notifications/addBlock", blockData);
      this.$store.commit(
        "notifications/setSelectedBlockIndex",
        this.blocks.length - 1
      );
    },
    exitEditing: function () {
      this.featuredImage = [];
      this.$store.dispatch("notifications/clearBuilder").then(() => {
        this.$store.commit("notifications/setNavigation", "list");
      });
    },
    deletePost: function () {
      let confirm = window.confirm(
        "About to permanently remove post, this can not be undone. Continue?"
      );

      if (confirm) {
        this.$store.dispatch("notifications/removePost").then(() => {
          this.$store.commit("notifications/setNavigation", "list");
        });
      }
    },
  },
};
</script>
